import React from "react"
import "../../works/animan.css"
import Footer from "../../../components/footer"
import WorkHeader from "../../../components/workHeader"
import animan from "../../../../static/work_headers/animan.png"
import Dict from "../.././../langs/dict"
import WorkAbout from "../../../components/workAbout"
import Perk from "../../../components/perks"
import animan2 from "../../../../static/works_perks/animan_1.png"
import animan1 from "../../../../static/works_perks/animan_2.png"
import animan3 from "../../../../static/works_perks/animan_3.png"
import Contact from "../../../components/contact"
import logo from "../../../../static/logos/animan_logo.png"
import Layout from "../../../components/layoutWorks"

export default () => {
  const lang = "fr"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Site Web - Animan"
      seoDescription="Pour la création du nouveau site Animan, le brief était de crééer une plateforme pour propulser le magazien dans l'ère digitale. Un nouveau medium pour partager l'expérience Animan."
    >
      <div id="works-animan-background">
        <WorkHeader
          title={dict.getTrans("work_animan_title")}
          subTitle={dict.getTrans("work_animan_subtitle")}
          pic={animan}
          navLang={lang}
          hl="#A0BC97"
          pageUrl="/works/animan"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("animan_about_title")}
        p1={dict.getTrans("animan_about_p1")}
        p2={dict.getTrans("animan_about_p2")}
        logo={logo}
      />
      <div id="works-animan-perks-holder">
        <Perk
          title={dict.getTrans("animan_perk1_title")}
          image={animan1}
          text={dict.getTrans("animan_perk1_text")}
        />
        <Perk
          title={dict.getTrans("animan_perk2_title")}
          image={animan2}
          text={dict.getTrans("animan_perk2_text")}
        />
        <Perk
          title={dict.getTrans("animan_perk3_title")}
          image={animan3}
          text={dict.getTrans("animan_perk3_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
